import React from 'react';
import './ModalFormItem.css'
import $ from "jquery";

const ModalFormItem = props => {
    let item = props.item;
    return (
        <label className="modal-content__item modal-item data-item">
            <input type="radio" className="modal-item__input" onChange={check} name="choose" value={item.name}/>
            <span className=" modal-item__body">
                <span className="modal-item__title">
                    <span className="modal-item__name">{item.name}</span>
                    <span className="modal-item__input-clone"/>
                </span>
                <span className="modal-item__old-price">{item.oldPrice}Р</span>
                <span className="modal-item__line"/>
                <span className="modal-item__new-price modal-price">
                    {item.price}₽
                    <span className="modal-price__discount">{item.discount}</span>
                </span>
            </span>
        </label>
    );
};

export default ModalFormItem;

function check(e) {
    let input = e.target;
    let item = input.parentElement;
    $(item).closest("form").find('.data-item').removeClass('checked');
    if (input.checked)
        $(item).addClass("checked");
}