import React from 'react';
import $ from 'jquery';
import './Header.css';
import reactInit from './../../index';

const Header = props => {
    return (
        <header className="header text-center">
            <span className="timer header__timer container">
                <span className="timer__label">Скидка действует:</span>
                <span className="timer__counter">
                    <span className="timer__item timer-minutes">02</span>
                    <span className="timer__delimiter"/>
                    <span className="timer__item timer-seconds">00</span>
                </span>
            </span>
        </header>
    );
};

(function timer(time = 120) {
    let minutes = parseInt(time / 60);
    let seconds = time % 60;
    let timerContainer = $('.timer');

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    $(".timer-minutes").html(minutes);
    $(".timer-seconds").html(seconds);

    time--;

    if (time < 30)
        timerContainer.addClass('danger');

    if (time >= 0)
        setTimeout(() => timer(time), 1000);
    else {
        timerContainer.removeClass('danger').addClass('stopped');
        $('.data-item__oldprice, .data-item__discount, .price__new').addClass('disabled');

        $(".data-item").each((i, item) => {
            let oldPrice = $(item).find('.data-item__oldprice').text();
            $(item).find(".price__new").html(oldPrice);
        });

        setTimeout(() => {
            $('.data-item__oldprice, .data-item__discount').remove();
            $('.price__new').removeClass('disabled');
        }, 1000);

        setTimeout(() => $('.modal').show(), 2000);
    }


})();

export default Header;