import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import $ from 'jquery';
import reportWebVitals from './reportWebVitals';
import './fonts/fonts';

const root = ReactDOM.createRoot(document.getElementById('root'));

const render = data => {
    root.render(
        <React.StrictMode>
            <App data={data}/>
        </React.StrictMode>
    );
};

let data = [
    {id: 1, name: '1 неделя', price: '699', isPopular: 1},
    {id: 2, name: '1 месяц', price: '999', isPopular: 1},
    {id: 3, name: '3 месяца', price: '2990', isPopular: 1},
    {id: 4, name: 'Навсегда', price: '5990', isPopular: 1},

    {id: 5, name: '1 неделя', price: '599', isDiscount: 1},
    {id: 6, name: '1 месяц', price: '799', isDiscount: 1},
    {id: 7, name: '3 месяца', price: '1690', isDiscount: 1},
];

render(data);

/*fetch("https://t-pay.iqfit.app/subscribe/list-test")
    .then(response => response.json())
    .then(data => render(data));*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();