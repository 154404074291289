import React from 'react';
import ModalFormItem from "./ModalFormItem/ModalFormItem";
import './ModalForm.css'

const ModalForm = props => {
    let unpopularItems = props.data.filter(item => item.isDiscount);
    let extraData = [
        {discount: "-40%", oldPrice: 999},
        {discount: "-50%", oldPrice: 1690},
        {discount: "-60%", oldPrice: 5990},
    ];
    unpopularItems = unpopularItems.map((item, i) => Object.assign(item, extraData[i]));
    return (
        <form className="modal-content__body" method="post" action="">
            <div className="modal-form">
                {unpopularItems.map(item => <ModalFormItem key={item.id} item={item}/>)}
            </div>
            <button type="button" className="btn modal-body__btn">Начать тренироваться</button>
        </form>
    )
};

export default ModalForm;