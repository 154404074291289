import React from 'react';
import ModalForm from "./ModalForm/ModalForm";
import './Modal.css';
import $ from 'jquery';

const Modal = props => {
    return (
        <div className="modal" onClick={closeModal}>
            <div className="modal__container container">
                <div className="modal__body modal-body">
                    <div className="modal-body__label">горящее предложение</div>
                    <div className="modal-body__close" onClick={closeModalBtn}/>
                    <div className="modal-body__header modal-header">
                        <div className="modal-header__title">Не упусти свой <span>последний шанс</span></div>
                        <div className="modal-header__text">
                            Мы знаем, как трудно начать.. <strong>Поэтому!</strong>
                            <div className="modal-header__gift-text">
                                Дарим скидку для <span>лёгкого старта</span> 🏃‍♂️
                            </div>
                        </div>
                    </div>
                    <div className="modal-body__content modal-content">
                        <div className="modal-content__title">Посмотри, что мы для тебя приготовили 🔥</div>
                        <ModalForm data={props.data}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;

let closeModalBtn = e => $(e.target).closest('.modal').hide();
let closeModal = e => {
    if (!$(e.target).closest('.modal-body').length)
        $('.modal').hide();
};
$(document.body).on('keyup', e => e.keyCode === 27 ? $('.modal').hide() : false);